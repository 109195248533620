<template>
  <div>
    <TourTable
      ref="table"
      :table-name="tableName"
      :setting-columns="columns"
      :actions="actions"
      :filters.sync="filters"
      :search="search"
      :setting-apis="apis"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
      @deletedEntry="onDeletedEntry"
    ></TourTable>
  </div>
</template>
<script>
import TourTable from '@components/_common/List/TourTable';

export default {
  data() {
    return {
      tableName: this.$t('users.user list'),
      apis: {
        list: {
          endpoint: this.$consts.API.ADMIN.TOUR_ORDER_TEMPLATES,
        },
        delete: {
          endpoint: this.$consts.API.ADMIN.TOUR_ORDER_TEMPLATES_DELETE,
        }
      },
      actions: {
        name: "tour_orders",
        exportEntries: true,
        editEntry: true,
        deleteEntry: true,
        routerLink: "tour_orders/",
      },
      search: {
        searchAll: false,
        reset: true,
      },
      filters: [
        {
          name: 'prefecture_id',
          type: 'equal',
          label: this.$t('tour_order_template.area_prefecture'),
          placeholder: this.$t('tour_order_template.area_prefecture'),
          uiType: 'select',
          boundaryClass: 'col-md-4 col-sm-12',
          options: []
        },
        {
          name: 'city_id',
          type: 'equal',
          label: this.$t('tour_order_template.area_city'),
          placeholder: this.$t('tour_order_template.area_city'),
          uiType: 'select',
          boundaryClass: 'col-md-4 col-sm-12',
          options: []
        },
        {
          name: 'tourname_name',
          type: 'multi_like',
          label: this.$t('tour_order_template.name'),
          uiType: 'multi_select',
          ui_options: {
            // tags: true,
            closeOnSelect: true,
          },
          boundaryClass: 'col-md-4 col-sm-12',
        },
        {
          name: 'price',
          type: 'greater_or_equal',
          label: this.$t('tour_order_template.price'),
          placeholder: this.$t('tour_order_template.price'),
          uiType: 'select',
          boundaryClass: 'col-md-4 col-sm-12',
          options: [
            {
              id: "none",
              name: "下限なし"
            },
            {
              id: "100000",
              name: "100,000円",
            },
            {
              id: "200000",
              name: "200,000円",
            },
            {
              id: "300000",
              name: "300,000円",
            },
            {
              id: "400000",
              name: "400,000円",
            },
            {
              id: "500000",
              name: "500,000円",
            },
            {
              id: "600000",
              name: "600,000円",
            },
            {
              id: "700000",
              name: "700,000円",
            },
            {
              id: "800000",
              name: "800,000円",
            },
            {
              id: "900000",
              name: "900,000円",
            },
            {
              id: "1000000",
              name: "1,000,000円",
            },
          ]
        },
        {
          name: 'price',
          type: 'less_or_equal',
          label: " ",
          placeholder: this.$t('tour_order_template.price'),
          uiType: 'select',
          boundaryClass: 'col-md-4 col-sm-12',
          options: [
            {
              id: "",
              name: "下限なし"
            },
            {
              id: "100000",
              name: "100,000円",
            },
            {
              id: "200000",
              name: "200,000円",
            },
            {
              id: "300000",
              name: "300,000円",
            },
            {
              id: "400000",
              name: "400,000円",
            },
            {
              id: "500000",
              name: "500,000円",
            },
            {
              id: "600000",
              name: "600,000円",
            },
            {
              id: "700000",
              name: "700,000円",
            },
            {
              id: "800000",
              name: "800,000円",
            },
            {
              id: "900000",
              name: "900,000円",
            },
            {
              id: "1000000",
              name: "1,000,000円",
            },
            {
              id: "99999999999999",
              name: "上限なし",
            },
          ]
        },
        {
          name: 'tour_type',
          type: 'equal',
          label: this.$t('tour_order_template.tour_type'),
          placeholder: this.$t('tour_order_template.tour_type'),
          uiType: 'select',
          boundaryClass: 'col-md-4 col-sm-12',
          options: [
            {
              id: 0,
              name: this.$t('common.select_all'),
            },
            {
              id: 1,
              name:  this.$t('tour_order_template.normal_type'),
            },
            {
              id: 2,
              name:  this.$t('tour_order_template.furusato_type'),
            },
          ]
        },
      ],
      entrySelectedDelete: {},
      entryToSave: {active: 1},
      entryToEdit: {},
      columns: [
        {
          name: 'tourname_name',
          label: this.$t('tour_order_template.name'),
          computeATag: (entry) => {

          }
        },
        {
          name: 'type',
          label: this.$t('tour_order_template.tour_type'),
          computedHtml: entry => {
            let type = this.$t('tour_order_template.normal_type');
            if (entry.type == 2) {
              type = this.$t('tour_order_template.furusato_type');
            }
            return type;
          }
        },
        {name: 'tourname_explain', label: this.$t('tour_order_template.description')},
        {name: 'price', label: this.$t('tour_order_template.price')},
        {name: 'prefectures', label: this.$t('tour_order_template.area_prefecture'), class: 'min-w-100'},
        {name: 'cities', label: this.$t('tour_order_template.area_city'), class: 'min-w-100'},
      ]
    }
  },
  components: {TourTable},
  mounted() {
    this.getAlias();
    this.getTourSelectName();
  },
  methods: {
    async getAlias() {
      const res = await this.$request.get(this.$consts.API.ADMIN.TOUR_PREFECTURES, {
        sortType: "asc",
        sortBy: "code",
      });

      if (!res.hasErrors()) {
        this.filters[0]['options'] = res.data.data;
        this.filters[0]['options'].push({
          id: 0,
          name: this.$t('common.select_all'),
        });

        this.filters[1]['options'] = [];
      }
    },
    getTourSelectName()
    {
      this.$request.get(this.$consts.API.ADMIN.TOUR_ORDERS_SELECT_NAME).then(res => {
        if(!res.hasErrors()) {
          this.filters[2].options = res.data;
          this.filters = _.cloneDeep(this.filters)
        }
      })
    },
    onResetAllSearch() {
      this.getTourSelectName();
    },
    onSearch() {
      this.getTourSelectName();
    },
    onDeletedEntry() {
      this.getTourSelectName();
    },
  }
}
</script>
